import { Component, OnInit } from '@angular/core';
import { CarouselImagesData } from '../data/carouselImages';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {

  explore_images: CarouselImagesData [] = [];

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let sectionOne = document.querySelector(".home__intro");

    let faders = document.querySelectorAll(".fade-in");
    let sliders = document.querySelectorAll(".slide-in");

    let sectionOneOptions = {
      rootMargin: "-200px 0px 0px 0px"
    };

    let sectionOneObserver = new IntersectionObserver(function(entries, sectionOneObserver) {
      entries.forEach(entry => {
        let header = document.querySelector("header");
        let logo = document.querySelector(".logo_image");
        let subtitle = document.querySelector(".subtitle");
        if (!entry.isIntersecting) {
          header.classList.add("nav-scrolled");
          logo.classList.add("logo_image_scrolled");
          subtitle.classList.add("subtitle_scrolled");
        } else {
          header.classList.remove("nav-scrolled");
          logo.classList.remove("logo_image_scrolled");
          subtitle.classList.remove("subtitle_scrolled");
        }

      });
    },
    sectionOneOptions);

    let appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -150px 0px"
    };

    let appearOnScroll = new IntersectionObserver(function(entries, appearOnScroll) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add("appear");
          appearOnScroll.unobserve(entry.target);
        }
      });
    },
    appearOptions);


    sectionOneObserver.observe(sectionOne);

    faders.forEach(fader => {
      appearOnScroll.observe(fader);
    });

    sliders.forEach(slider => {
      appearOnScroll.observe(slider);
    });

  }


}
