import { Component, OnInit } from '@angular/core';
import { CarouselImagesData } from '../../data/carouselImages';

@Component({
  selector: 'app-freestyle',
  templateUrl: './freestyle.component.html',
  styleUrls: ['./freestyle.component.css']
})
export class FreestyleComponent implements OnInit {

  // data to be transported to room_template
  images: CarouselImagesData [] = [];
  title: string = "";
  numberOfBeds: string = "";
  bedTypes: string = "";
  numberOfExtraRemovableBeds: string = "";
  extraRemovableBedType: string = "";
  maxCapacity: string = "";
  bathroom: string = "";

  constructor() {
      this.images[0] = {
        image: "../assets/rooms/freestyle/freestyle1.jpg", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[1] = { 
        image: "../assets/rooms/freestyle/freestyle1.jpg", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[2] = { 
        image: "../assets/rooms/freestyle/freestyle1.jpg", 
        title: "", 
        link: null,
        description: `
        ` };

      this.title = "FREESTYLE ROOM";
      this.numberOfBeds = "8";
      this.bedTypes = "beds type bunk";
      this.numberOfExtraRemovableBeds = "1";
      this.extraRemovableBedType = "extra removable bed";
      this.maxCapacity = "9";
      this.bathroom = "shared";
      }
  ngOnInit() {
  }

}
