import { Component, OnInit, ViewChild, ElementRef, Directive, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CarouselImagesData } from '../data/carouselImages';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  home_images: CarouselImagesData [] = [];

  constructor() {
    this.home_images[0] = {
      image: "../assets/rooms/dream/main_big.jpg", 
      title: "Dream Room",
      link: '/rooms/dream',
      description: `
        2 bed room
        <br>
        private bathroom
      ` };
    this.home_images[1] = { 
      image: "../assets/rooms/freestyle/freestyle1.jpg", 
      title: "Freestyle Room",
      link: '/rooms/freestyle',
      description: `
      8 Bed Room
      <br>
      Shared Bathroom
      ` };
    this.home_images[2] = { 
      image: "../assets/rooms/jungle/site_IMG_20160617_124644.jpg", 
      title: "Jungle Room",
      link: '/rooms/jungle',
      description: `
      10 Bed Room
      <br>
      Shared Bathroom
      ` };
    this.home_images[3] = { 
      image: "../assets/rooms/moon/1_med.JPG", 
      title: "Moon Room",
      link: '/rooms/moon',
      description: `
      2 Bed Room
      <br>
      Shared Bathroom
      ` };
    this.home_images[4] = { 
      image: "../assets/rooms/sun/4.jpg", 
      title: "Sun Room",
      link: '/rooms/sun',
      description: `
      8 Bed Room
      <br>
      Shared Bathroom
      ` };
    this.home_images[5] = { 
      image: "../assets/rooms/tree/img1_medium.jpg", 
      title: "Tree Room",
      link: '/rooms/tree',
      description: `
      6 Bed Room
      <br>
      Shared Bathroom
      ` };
    // this.home_images[6] = { 
    //   image: "../assets/rooms/hammock/hammock.jpg", 
    //   title: "Hammock", 
    //   description: `
    //     - 1 Hammock Open Room<br>
    //     - Shared Bathroom
    //   ` };
  }

  ngOnInit() {
  }

  //
  //  This is needed by:
  //
  //  1. Raul's face
  //  2. Highlights
  //
  ngAfterViewInit() {
    let sectionOne = document.querySelector(".home__intro");

    let faders = document.querySelectorAll(".fade-in");
    let sliders = document.querySelectorAll(".slide-in");

    let sectionOneOptions = {
      rootMargin: "-200px 0px 0px 0px"
    };

    let sectionOneObserver = new IntersectionObserver(function(entries, sectionOneObserver) {
      entries.forEach(entry => {
        let header = document.querySelector("header");
        let logo = document.querySelector(".logo_image");
        let subtitle = document.querySelector(".subtitle");
        if (!entry.isIntersecting) {
          header.classList.add("nav-scrolled");
          logo.classList.add("logo_image_scrolled");
          subtitle.classList.add("subtitle_scrolled");
        } else {
          header.classList.remove("nav-scrolled");
          logo.classList.remove("logo_image_scrolled");
          subtitle.classList.remove("subtitle_scrolled");
        }
        
      });
    },
    sectionOneOptions);

    let appearOptions = {
      threshold: 0,
      rootMargin: "0px 0px -150px 0px"
    };

    let appearOnScroll = new IntersectionObserver(function(entries, appearOnScroll) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return;
        } else {
          entry.target.classList.add("appear");
          appearOnScroll.unobserve(entry.target);
        }
      });
    },
    appearOptions);


    sectionOneObserver.observe(sectionOne);

    faders.forEach(fader => {
      appearOnScroll.observe(fader);
    });

    sliders.forEach(slider => {
      appearOnScroll.observe(slider);
    });

  }

}
