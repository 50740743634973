import { Component, OnInit } from '@angular/core';
import { CarouselImagesData } from '../../data/carouselImages';

@Component({
  selector: 'app-jungle',
  templateUrl: './jungle.component.html',
  styleUrls: ['./jungle.component.css']
})
export class JungleComponent implements OnInit {

  // data to be transported to room_template
  images: CarouselImagesData [] = [];
  title: string = "";
  numberOfBeds: string = "";
  bedTypes: string = "";
  numberOfExtraRemovableBeds: string = "";
  extraRemovableBedType: string = "";
  maxCapacity: string = "";
  bathroom: string = "";

  constructor() {
      this.images[0] = {
        image: "../assets/rooms/jungle/site_IMG_20160617_124644.jpg", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[1] = { 
        image: "../assets/rooms/jungle/site_IMG_20160617_124644.jpg", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[2] = { 
        image: "../assets/rooms/jungle/site_IMG_20160617_124644.jpg", 
        title: "", 
        link: null,
        description: `
        ` };

      this.title = "JUNGLE ROOM";
      this.numberOfBeds = "10";
      this.bedTypes = "beds type bunk";
      this.numberOfExtraRemovableBeds = "2";
      this.extraRemovableBedType = "extra removable beds";
      this.maxCapacity = "12";
      this.bathroom = "shared";
    }
  ngOnInit() {
  }

}
