import { Component, OnInit } from '@angular/core';
import { CarouselImagesData } from '../../data/carouselImages';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.css']
})
export class TreeComponent implements OnInit {

  // data to be transported to room_template
  images: CarouselImagesData [] = [];
  title: string = "";
  numberOfBeds: string = "";
  bedTypes: string = "";
  numberOfExtraRemovableBeds: string = "";
  extraRemovableBedType: string = "";
  maxCapacity: string = "";
  bathroom: string = "";

  constructor() {
      this.images[0] = {
        image: "../assets/rooms/tree/img1_medium.jpg", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[1] = { 
        image: "../assets/rooms/tree/img1_medium.jpg", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[2] = { 
        image: "../assets/rooms/tree/img1_medium.jpg", 
        title: "", 
        link: null,
        description: `
        ` };

      this.title = "SUN ROOM";
      this.numberOfBeds = "6";
      this.bedTypes = "beds type bunk";
      this.numberOfExtraRemovableBeds = "1";
      this.extraRemovableBedType = "extra removable bed";
      this.maxCapacity = "7";
      this.bathroom = "shared";
    }
  ngOnInit() {
  }

}
