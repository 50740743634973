import { Component, OnInit } from '@angular/core';
import { CarouselImagesData } from '../../data/carouselImages';

@Component({
  selector: 'app-moon',
  templateUrl: './moon.component.html',
  styleUrls: ['./moon.component.css']
})
export class MoonComponent implements OnInit {

  // data to be transported to room_template
  images: CarouselImagesData [] = [];
  title: string = "";
  numberOfBeds: string = "";
  bedTypes: string = "";
  numberOfExtraRemovableBeds: string = "";
  extraRemovableBedType: string = "";
  maxCapacity: string = "";
  bathroom: string = "";

  constructor() {
      this.images[0] = {
        image: "../assets/rooms/moon/1_med.JPG", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[1] = { 
        image: "../assets/rooms/moon/1_med.JPG", 
        title: "", 
        link: null,
        description: `
        ` };
      this.images[2] = { 
        image: "../assets/rooms/moon/1_med.JPG", 
        title: "", 
        link: null,
        description: `
        ` };

      this.title = "MOON ROOM";
      this.numberOfBeds = "2";
      this.bedTypes = "twin beds";
      this.numberOfExtraRemovableBeds = "0 ";
      this.extraRemovableBedType = "extra removable bed";
      this.maxCapacity = "2";
      this.bathroom = "shared";
    }

  ngOnInit() {
  }

}
