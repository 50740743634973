// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBLFLY5e-XhRfH2hoAE29GYOFpL-kV5I2w",
    authDomain: "freeborn-hostel.firebaseapp.com",
    databaseURL: "https://freeborn-hostel.firebaseio.com",
    projectId: "freeborn-hostel",
    storageBucket: "freeborn-hostel.appspot.com",
    messagingSenderId: "596082438082",
    appId: "1:596082438082:web:0659a35a2232ad59"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
