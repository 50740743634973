import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// material design
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRippleModule} from '@angular/material/core';

import { AppComponent } from './app.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { HomeComponent } from './home/home.component';
import { RoomsComponent } from './rooms/rooms.component';
import { ExploreComponent } from './explore/explore.component';
import { LocationComponent } from './location/location.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { CarouselComponent } from './carousel/carousel.component';
import {MatGridListModule} from '@angular/material/grid-list';
import { Routes, RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { MapComponent } from './map/map.component';
import { EntertainmentComponent } from './entertainment/entertainment.component';
import { FreestyleComponent } from './rooms/freestyle/freestyle.component';
import { MoonComponent } from './rooms/moon/moon.component';
import { TreeComponent } from './rooms/tree/tree.component';
import { SunComponent } from './rooms/sun/sun.component';
import { DreamComponent } from './rooms/dream/dream.component';
import { JungleComponent } from './rooms/jungle/jungle.component';
import { SharedBathroomComponent } from './rooms/bathroom/shared-bathroom/shared-bathroom.component';
import { PrivateBathroomComponent } from './rooms/bathroom/private-bathroom/private-bathroom.component';

// init firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { GroupsComponent } from './groups/groups.component';
import { RoomTemplateComponent } from './rooms/room-template/room-template.component';
import { HighlightsComponent } from './highlights/highlights.component';


const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'rooms', component: RoomsComponent },
  { path: 'rooms/moon', component: MoonComponent },
  { path: 'rooms/sun', component: SunComponent },
  { path: 'rooms/tree', component: TreeComponent },
  { path: 'rooms/dream', component: DreamComponent },
  { path: 'rooms/freestyle', component: FreestyleComponent },
  { path: 'rooms/jungle', component: JungleComponent },
  { path: 'location', component: LocationComponent },
  { path: 'entertainment', component: EntertainmentComponent },
  { path: 'groups', component: GroupsComponent },
  { path: 'explore', component: ExploreComponent },
  { path: 'contact', component: ContactComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    ContactFormComponent,
    HomeComponent,
    RoomsComponent,
    ExploreComponent,
    LocationComponent,
    ContactComponent,
    FooterComponent,
    CarouselComponent,
    MapComponent,
    EntertainmentComponent,
    FreestyleComponent,
    MoonComponent,
    TreeComponent,
    SunComponent,
    DreamComponent,
    JungleComponent,
    SharedBathroomComponent,
    PrivateBathroomComponent,
    GroupsComponent,
    RoomTemplateComponent,
    HighlightsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatCardModule,
    MatStepperModule,
    MatSidenavModule,
    MatGridListModule,
    MatRippleModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled' // Add options right here
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyArBPkbCpuPrxDp4diU4YpXMRNdQixuNeI'
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  exports: [RouterModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
