import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'; 
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public firebaseService: FirebaseService
  ) { }

  exampleForm: FormGroup;
  avatarLink: string = "assets\raul\raul_face.png";

  validation_messages = {
   'name': [
     { type: 'required', message: 'Name is required.' }
   ],
   'email': [
     { type: 'required', message: 'Email is required.' }
   ],
   'room': [
     { type: 'required', message: 'Room is required.' },
   ],
   'date': [
     { type: 'required', message: 'Date is required.' },
   ],
   'arrival': [
     { type: 'required', message: 'Arrival is required.' },
   ],
   'nights': [
     { type: 'required', message: 'Nights is required.' },
   ],
   'people': [
     { type: 'required', message: 'People is required.' },
   ],
   'comments': [
     { type: 'required', message: 'Comments is required.' },
   ]
 };

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.exampleForm = this.fb.group({
      name: ['', Validators.required ],
      email: ['', Validators.required ],
      room: ['', Validators.required ],
      date: ['', Validators.required ],
      arrival: ['', Validators.required ],
      nights: ['', Validators.required ],
      people: ['', Validators.required ],
      comments: ['', Validators.required ]
    });
  }

  openDialog() {
  }

  resetFields(){
    this.exampleForm = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      room: new FormControl('', Validators.required),
      date: new FormControl('', Validators.required),
      arrival: new FormControl('', Validators.required),
      nights: new FormControl('', Validators.required),
      people: new FormControl('', Validators.required),
      comments: new FormControl('', Validators.required),
    });
  }

  onSubmit(value){
    this.firebaseService.createUser(value)
    .then(
      res => {
        this.resetFields();
        this.router.navigate(['/']);
      }
    )
  }
 

}
