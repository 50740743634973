import { Component, OnInit, Input } from '@angular/core';
import { CarouselImagesData } from '../data/carouselImages';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  track: HTMLElement;
  slides:any;
  nextButton:any;
  nextButtonShadow:any;
  prevButton:any;
  prevButtonShadow:any;
  dotsNav:any;
  dots:any;
  slideWidth:any;

  @Input() images: CarouselImagesData [] = []; // decorate the property with @Input()

  room_title: string = "";
  room_description: string = "";
  room_link: string = null;

  constructor() {
  }

  ngOnInit() {
    this.room_title = this.images[0].title;
    this.room_description = this.images[0].description;
    this.room_link = this.images[0].link;
  }

  ngAfterViewInit() {
    if (this.room_title === "") {
      let text_shadow:HTMLElement = document.querySelector('.home_text_shadow');
      text_shadow.hidden = true;
    }

    this.track = document.querySelector('.carousel__track');
    this.slides = Array.from(this.track.children);
    this.nextButton = document.querySelector('.carousel__button--right');
    this.nextButtonShadow = document.querySelector('.carousel__button__shadow--right');
    console.log("nextButton: ", this.nextButton);
    this.prevButton = document.querySelector('.carousel__button--left');
    this.prevButtonShadow = document.querySelector('.carousel__button__shadow--left');
    console.log("prevButton: ", this.prevButton);
    this.dotsNav = document.querySelector('.carousel__nav');
    this.dots = Array.from(this.dotsNav.children);
    this.slideWidth = this.slides[0].getBoundingClientRect().width;
    // START - added by me - am pus *ngFor in html
    this.slides[0].classList.add('current-slide');
    let button_indicator = document.querySelector('.carousel__indicator');
    button_indicator.classList.add('current-slide');
    // END - added by me - am pus *ngFor in html

    let setSlidePosition = (slide, index) => {
      slide.style.left = this.slideWidth * index + 'px';
    }
    this.slides.forEach(setSlidePosition);
  }

  // Move slide
  moveToSlide(currentSlide, targetSlide, targetDot, targetIndex?) {
  const currentDot = this.dotsNav.querySelector('.current-slide');
  this.track.style.transform = 'translateX(-'+ targetSlide.style.left + ')';
  currentSlide.classList.remove('current-slide');
  targetSlide.classList.add('current-slide');

  currentDot.classList.remove('current-slide');
  targetDot.classList.add('current-slide');


  if (targetIndex === 0) {
    this.prevButton.classList.add('hidden');
    this.prevButtonShadow.classList.add('hidden');
    this.nextButton.classList.remove('hidden');
    this.nextButtonShadow.classList.remove('hidden');
  } else if (targetIndex == this.slides.length - 1) {
    this.prevButton.classList.remove('hidden');
    this.prevButtonShadow.classList.remove('hidden');
    this.nextButton.classList.add('hidden');
    this.nextButtonShadow.classList.add('hidden');
  } else {
    this.prevButton.classList.remove('hidden');
    this.prevButtonShadow.classList.remove('hidden');
    this.nextButton.classList.remove('hidden');
    this.nextButtonShadow.classList.remove('hidden');
  }
}

slideIndex() {
  const currentSlide = this.track.querySelector('.current-slide');
  const prevSlide = currentSlide.previousElementSibling;
  return this.slides.findIndex(slide => slide === prevSlide);
}

prevButtonClick_Pre() {
  const slideIndex = this.slideIndex();
  this.room_title = this.images[slideIndex].title;
  this.room_description = this.images[slideIndex].description;
  this.room_link = this.images[slideIndex].link;
  let text_shadow:HTMLElement = document.querySelector('.home_text_shadow');
  if (this.room_title === "") {
    text_shadow.hidden = true;
  } else {
    text_shadow.hidden = false;
  }

  const room_title:HTMLElement = document.querySelector('.home_room_title');
  const room_description:HTMLElement = document.querySelector('.home_room_description');

  room_title.classList.remove('previous_button_animation_class');
  room_title.classList.remove('previous_button_animation_delay_class');
  room_title.classList.remove('next_button_animation_class');
  room_title.classList.remove('next_button_animation_delay_class');
  void room_title.offsetWidth;
  room_title.classList.add('previous_button_animation_class');

  room_description.classList.remove('previous_button_animation_class');
  room_description.classList.remove('previous_button_animation_delay_class');
  room_description.classList.remove('next_button_animation_class');
  room_description.classList.remove('next_button_animation_delay_class');
  void room_description.offsetWidth;
  room_description.classList.add('previous_button_animation_delay_class');

  this.prevButtonClick();
}

// when I click left, move slides to the left
prevButtonClick() {
  const currentSlide = this.track.querySelector('.current-slide');
  const prevSlide = currentSlide.previousElementSibling;
  const slideIndex = this.slides.findIndex(slide => slide === prevSlide);
  const targetDot = this.dots[slideIndex];
  //move to the next slide
  this.moveToSlide(currentSlide, prevSlide, targetDot, slideIndex);
  if (prevSlide == currentSlide) {
    // this.prevButton.classlist.add('hidden');
    this.moveToSlide(currentSlide, prevSlide, targetDot);
  }
}

nextButtonClick_Pre() {
  const slideIndex = this.slideIndex() + 2;
  this.room_title = this.images[slideIndex].title;
  this.room_description = this.images[slideIndex].description;
  this.room_link = this.images[slideIndex].link;
  let text_shadow:HTMLElement = document.querySelector('.home_text_shadow');
  if (this.room_title === "") {
    text_shadow.hidden = true;
  } else {
    text_shadow.hidden = false;
  }

  const room_title:HTMLElement = document.querySelector('.home_room_title');
  const room_description:HTMLElement = document.querySelector('.home_room_description');

  room_title.classList.remove('previous_button_animation_class');
  room_title.classList.remove('previous_button_animation_delay_class');
  room_title.classList.remove('next_button_animation_class');
  room_title.classList.remove('next_button_animation_delay_class');
  void room_title.offsetWidth;
  room_title.classList.add('next_button_animation_class');

  room_description.classList.remove('previous_button_animation_class');
  room_description.classList.remove('previous_button_animation_delay_class');
  room_description.classList.remove('next_button_animation_class');
  room_description.classList.remove('next_button_animation_delay_class');
  void room_description.offsetWidth;
  room_description.classList.add('next_button_animation_delay_class');

  this.nextButtonClick();

}

// when I click right, move slides to the right
nextButtonClick() {
  const currentSlide = this.track.querySelector('.current-slide');
  const nextSlide = currentSlide.nextElementSibling;
  const slideIndex = this.slides.findIndex(slide => slide === nextSlide);
  const targetDot = this.dots[slideIndex];

  //move to the next slide
  if (slideIndex != -1)
    this.moveToSlide(currentSlide, nextSlide, targetDot, slideIndex);
}

// when I click the nav indicators, move to that slide
dotsClick(e: any) {
  const targetDot = e.target.closest('button');

  if (!targetDot) return;

  const currentSlide = this.track.querySelector('.current-slide');
  const targetIndex = this.dots.findIndex(dot => dot === targetDot);
  const targetSlide = this.slides[targetIndex];

  this.room_title = this.images[targetIndex].title;
  this.room_description = this.images[targetIndex].description;
  this.room_link = this.images[targetIndex].link;
  let text_shadow:HTMLElement = document.querySelector('.home_text_shadow');
  if (this.room_title === "") {
    text_shadow.hidden = true;
  } else {
    text_shadow.hidden = false;
  }

  this.moveToSlide(currentSlide, targetSlide, targetDot, targetIndex);
}


}
