import { Component, OnInit } from '@angular/core';
import { CarouselImagesData } from '../../data/carouselImages';

@Component({
  selector: 'app-dream',
  templateUrl: './dream.component.html',
  styleUrls: ['./dream.component.css']
})
export class DreamComponent implements OnInit {

  // data to be transported to room_template
  images: CarouselImagesData [] = [];
  title: string = "";
  numberOfBeds: string = "";
  bedTypes: string = "";
  numberOfExtraRemovableBeds: string = "";
  extraRemovableBedType: string = "";
  maxCapacity: string = "";
  bathroom: string = "";

  constructor() {
      this.images[0] = {
        image: "../assets/rooms/dream/main_big.jpg", 
        title: "",
        link: null, 
        description: `
        ` };
      this.images[1] = { 
        image: "../assets/rooms/dream/main_big.jpg", 
        title: "", 
        link: null, 
        description: `
        ` };
      this.images[2] = { 
        image: "../assets/rooms/dream/main_big.jpg", 
        title: "", 
        link: null, 
        description: `
        ` };
      this.title = "DREAM ROOM";
      this.numberOfBeds = "1";
      this.bedTypes = "king size bed";
      this.numberOfExtraRemovableBeds = "1";
      this.extraRemovableBedType = "extra removable bed";
      this.maxCapacity = "3";
      this.bathroom = "private";
      
    }

  ngOnInit() {
  }

}
