import { Component, OnInit, ViewChild } from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav', {static: false}) sidenav: MatSidenav;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
    // const menu_button = document.querySelector(".top-nav-menu-button");
    // menu_button.addEventListener('click', _ => {
    //   menu_button.classList.toggle('top-nav-menu-button_moved');
    // });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0)
    });
  }

  open() {
    this.sidenav.open();
  }

  close() {
    this.sidenav.close();

    // const menu_button = document.querySelector(".top-nav-menu-button");
    // menu_button.classList.toggle('top-nav-menu-button_moved');
  }

}
