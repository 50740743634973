import { Component, OnInit } from '@angular/core';
import { CarouselImagesData } from '../../data/carouselImages';

@Component({
  selector: 'app-sun',
  templateUrl: './sun.component.html',
  styleUrls: ['./sun.component.css']
})
export class SunComponent implements OnInit {

  // data to be transported to room_template
  images: CarouselImagesData [] = [];
  title: string = "";
  numberOfBeds: string = "";
  bedTypes: string = "";
  numberOfExtraRemovableBeds: string = "";
  extraRemovableBedType: string = "";
  maxCapacity: string = "";
  bathroom: string = "";

  constructor() {
      this.images[0] = {
        image: "../assets/rooms/sun/4.jpg",
        title: "",
        link: null,
        description: `
        ` };
      this.images[1] = {
        image: "../assets/rooms/sun/4.jpg",
        title: "",
        link: null,
        description: `
        ` };
      this.images[2] = {
        image: "../assets/rooms/sun/4.jpg",
        title: "",
        link: null,
        description: `
        ` };

      this.title = "SUN ROOM";
      this.numberOfBeds = "8";
      this.bedTypes = "beds type bunk";
      this.numberOfExtraRemovableBeds = "2";
      this.extraRemovableBedType = "extra removable beds";
      this.maxCapacity = "10";
      this.bathroom = "shared";
    }
  ngOnInit() {

  }

}
