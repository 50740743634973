import { Component, OnInit, Input } from '@angular/core';
import { CarouselImagesData } from '../../data/carouselImages';

@Component({
  selector: 'app-room-template',
  templateUrl: './room-template.component.html',
  styleUrls: ['./room-template.component.css']
})
export class RoomTemplateComponent implements OnInit {

  // decorate the property with @Input()
  @Input() images: CarouselImagesData [] = [];
  @Input() title: string = "";
  @Input() numberOfBeds: string = "";
  @Input() bedTypes: string = "";
  @Input() numberOfExtraRemovableBeds: string = "";
  @Input() extraRemovableBedType: string = "";
  @Input() maxCapacity: string = "";
  @Input() bathroom: string = "";

  constructor() {
    }

  ngOnInit() {
  }

}
