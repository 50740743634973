import {Component} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';

/** @title Form field with error messages */
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent {
  
  email = new FormControl('', [Validators.required, Validators.email]);

  getEmailErrorMessage() {
    return this.email.hasError('required') ? 
      'You must enter a value' :  this.email.hasError('email') ? 
      'Not a valid email' : '';
  }

  name = new FormControl('', [Validators.required, Validators.nullValidator]);

  getNameErrorMessage() {
    return this.name.hasError('required') ? 
      'You must enter a value' :  this.name.hasError('name') ? 
      'Not a name' : '';
  }
}
